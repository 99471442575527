import { RequestDocument, Variables, request } from 'graphql-request';

// GraphQL path is always a relative path.
// Routing to the appropriate server is handled at another level.
const URL = '/graphql';

export function createGqlAuthRequest<TVars extends Variables, TResp>(
  document: RequestDocument,
) {
  return (
    accessToken: string,
    variables: TVars,
    appId?: string,
    environment?: string,
  ): Promise<TResp> =>
    request<TResp, Variables>({
      url: URL,
      document,
      variables,
      requestHeaders: {
        Authorization: `Bearer ${accessToken}`,
        ...(!!appId && environment
          ? { 'x-app-id': appId, 'x-environment': environment }
          : {}),
      },
    });
}

export function createGqlRequest<TVars extends Variables, TResp>(
  document: RequestDocument,
) {
  return (
    variables: TVars,
    appId?: string,
    environment?: string,
  ): Promise<TResp> =>
    request<TResp, Variables>({
      url: URL,
      document,
      variables,
      ...(!!appId && !!environment
        ? {
            requestHeaders: { 'x-app-id': appId, 'x-environment': environment },
          }
        : {}),
    });
}
