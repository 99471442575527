import React from 'react';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { getChangelogRequest } from '../../../support/apps';
import {
  ChangelogsQueryVariables,
  ChangelogsQuery,
  Environment,
  Changelog,
} from '../../../gql/gqlRequests';
import { Option } from 'react-multi-select-component';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { RequestError } from '../../../types';
import { parsedRequestError } from '../../../utils/errors';
import { useMemo } from 'react';
import StandardTable from './StandardTable';
import moment from 'moment';
import { strings } from '../../../utils/strings';
import { Box } from '@chakra-ui/react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import Button from '../buttons/Button';

const DATE_FORMAT = 'DD/MM/yyyy HH:mm';

type ChangelogRow = {
  id: string;
  createdAt: Date;
  timestamp: Date;
  platform: string;
  madeBy: string;
  description: string;
  subRows?: Omit<ChangelogRow, 'subRows'>[];
};

type ProvidedChangelogs = {
  changelogs: {
    changelogs: Omit<Changelog, 'action'>[];
    platform: string;
  }[];
};

type QueryChangelogs = {
  appId: string;
  environment: Environment;
  platformIds: Option[];
};

type ChangelogsTableProps = {
  withTitle?: boolean;
  maxHeight?: string;
} & Partial<QueryChangelogs> &
  Partial<ProvidedChangelogs> &
  (
    | ({ type: 'provided' } & ProvidedChangelogs)
    | ({ type: 'query' } & QueryChangelogs)
  );

const columnHelper = createColumnHelper<ChangelogRow>();

const columns = [
  columnHelper.accessor('id', { footer: (props) => props.column.id }),
  columnHelper.accessor('createdAt', { footer: (props) => props.column.id }),
  columnHelper.accessor('platform', {
    header: strings.platforms.platform,
    cell: ({ row, getValue }) => (
      <Box paddingLeft={`${row.depth * 2}rem`} display={'flex'}>
        {row.getCanExpand() ? (
          <Button onClick={row.getToggleExpandedHandler()} variant={'text'}>
            {row.getIsExpanded() ? (
              <FiChevronDown size={20} />
            ) : (
              <FiChevronRight size={20} />
            )}
          </Button>
        ) : (
          ''
        )}
        <span>{getValue()}</span>
      </Box>
    ),
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor('timestamp', {
    header: strings.changelog.registeredAt,
    cell: (info) =>
      info.getValue() === null
        ? '-'
        : `${moment(info.getValue()).format(DATE_FORMAT)}`,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor('madeBy', {
    header: strings.changelog.changedBy,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor('description', {
    header: strings.common.description,
    footer: (props) => props.column.id,
  }),
];

export const ChangelogsTable = ({
  withTitle = true,
  type,
  appId = '',
  changelogs,
  environment = Environment.Development,
  platformIds = [],
  maxHeight,
}: ChangelogsTableProps) => {
  const isQuery = type === 'query';

  const getChangelogRequestFn = useAuthRequest<
    ChangelogsQueryVariables,
    ChangelogsQuery
  >(getChangelogRequest);
  const getChangelogQuery = useQuery<ChangelogsQuery, RequestError>({
    queryKey: ['changelogs', appId, environment, platformIds],
    queryFn: () =>
      getChangelogRequestFn({
        id: appId,
        environment,
        platforms: platformIds.map((p) => p.label),
      }),
    refetchInterval: 10 * 1000, //10s
    enabled: isQuery && platformIds.length !== 0,
    cacheTime: 0,
  });

  const changelogsFormatedData: ChangelogRow[] = useMemo(
    () =>
      (isQuery
        ? getChangelogQuery.data?.getChangelogs ?? []
        : changelogs ?? []
      ).map((c, index) => ({
        platform: `${c.platform} (${c.changelogs.length})`,
        id: `${index}`,
        createdAt: null as unknown as Date,
        timestamp: null as unknown as Date,
        madeBy: '-',
        description: '-',
        subRows: c.changelogs.map((cc) => ({
          id: cc.timestamp.toString(),
          createdAt: cc.timestamp,
          timestamp: cc.timestamp,
          platform: c.platform,
          madeBy: !cc.madeBy ? '-' : cc.madeBy,
          description: cc.readableChange.join(' '),
        })),
      })),
    [getChangelogQuery.data, changelogs, isQuery],
  );

  if (getChangelogQuery.isError) {
    parsedRequestError(getChangelogQuery.error);
  }

  return (
    <StandardTable
      isLoading={
        (getChangelogQuery.isLoading || getChangelogQuery.isFetching) &&
        platformIds.length !== 0
      }
      title={withTitle ? strings.changelog.changelogs : ''}
      columns={columns}
      data={changelogsFormatedData}
      canSearch={false}
      boldColumn="platform"
      maxHeight={maxHeight}
      withoutMenuCol
    />
  );
};
