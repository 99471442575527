import { useCallback, useContext } from 'react';
import { TokenContext } from '../contexts';
import { RequestErrorCode } from '../types';
import { Variables } from 'graphql-request';
import { refreshLoginRequest } from '../support/authentication';

function isUnauthenticatedError(error: any): boolean {
  return (
    error?.response?.errors?.some(
      (e: any) =>
        e?.extensions?.response?.statusCode ===
        RequestErrorCode.UNAUTHENTICATED,
    ) ?? false
  );
}

/** Only use this within components which are descendants of `RequiresAuthentication`. */
export default function useAuthRequest<TVars extends Variables, TResp>(
  requestFn: (
    accessToken: string,
    variables: TVars,
    appId?: string,
    environment?: string,
  ) => Promise<TResp>,
) {
  const {
    accessToken,
    refreshToken,
    setAccessToken,
    clearTokens,
    isAccessTokenValidAndUnexpired,
  } = useContext(TokenContext);

  // TODO: refactor this hook and useLogout so that this can call logout instead
  // of this pseudo-logout logic (useLogout uses this hook so can't use it here)
  const clearTokensAndStorage = useCallback(() => {
    clearTokens();
    localStorage.clear();
    sessionStorage.clear();
  }, [clearTokens]);

  const getNewAccessToken = useCallback(async () => {
    try {
      const response = await refreshLoginRequest({ refreshToken });
      const newAccessToken =
        response.refreshTokenLogin.authenticationResult?.accessToken;
      if (!newAccessToken) {
        throw Error('Successful login but no access token received.');
      }
      return newAccessToken;
    } catch (e) {
      clearTokensAndStorage();
      // must throw error for typescript, but RequiresAuthentication should navigate to /login
      throw e;
    }
  }, [clearTokensAndStorage, refreshToken]);

  const requestFnWithInitialTokenCheck = useCallback<
    (variables: TVars) => Promise<TResp>
  >(
    async (variables) => {
      let accessTokenToUse = accessToken;

      const appId = sessionStorage.getItem('app-id') ?? undefined;
      const environment = sessionStorage.getItem('environment') ?? undefined;

      if (isAccessTokenValidAndUnexpired()) {
        // if token appears valid as far as we can tell, try original request
        try {
          const resp = await requestFn(
            accessTokenToUse,
            variables,
            appId,
            environment,
          );
          // if that works, return the data
          return resp;
        } catch (e) {
          // if that doesn't work, but isn't a 401 error, throw the error
          if (!isUnauthenticatedError(e)) throw e;
        }
      }

      // otherwise, token appears invalid or using it produced a 401 error
      try {
        accessTokenToUse = await getNewAccessToken();
        setAccessToken(accessTokenToUse);
        // try the original request with the new token
        const resp = await requestFn(accessTokenToUse, variables);
        // if that works, return the data
        return resp;
      } catch (e) {
        if (isUnauthenticatedError(e)) {
          // if that doesn't work, and is a 401 error, then force logout
          clearTokensAndStorage();
          // must throw error for typescript, but RequiresAuthentication should navigate to /login
          throw e;
        } else {
          // otherwise, throw that (non-401) error
          throw e;
        }
      }
    },
    [
      accessToken,
      clearTokensAndStorage,
      getNewAccessToken,
      isAccessTokenValidAndUnexpired,
      requestFn,
      setAccessToken,
    ],
  );

  return requestFnWithInitialTokenCheck;
}
