import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import Page from '../../common/Page';
import { useParams } from 'react-router-dom';
import useAuthRequest from '../../../hooks/useAuthRequest';
import {
  Status,
  StatusDetailQuery,
  StatusDetailQueryVariables,
} from '../../../gql/gqlRequests';
import { statusDetailRequest } from '../../../support/apps';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import Details, {
  DetailsSection,
  SectionBody,
  SectionGrid,
  SectionTitle,
} from '../../common/Details';
import LabelAndValue from '../../common/LabelAndValue';
import { displayEnum } from '../../../utils';
import { parsedRequestError } from '../../../utils/errors';
import moment from 'moment';
import { ChangelogsTable } from '../../common/tables/ChangelogsTable';
import { strings } from '../../../utils/strings';

const DATE_FORMAT = 'DD/MM/yyyy HH:mm';

export default function PublishingStatusDetail() {
  const params = useParams();

  const statusId = params.statusId ?? '';

  const statusDetailFn = useAuthRequest<
    StatusDetailQueryVariables,
    StatusDetailQuery
  >(statusDetailRequest);

  const statusDetailQuery = useQuery<StatusDetailQuery, RequestError>({
    queryKey: ['status-details'],
    queryFn: () => statusDetailFn({ id: statusId }),
  });

  const statusData = statusDetailQuery.data?.getStatusByStatusId;

  if (statusDetailQuery.isError)
    throw parsedRequestError(statusDetailQuery.error);

  return (
    <Page
      title={strings.publish.appStatusDetail}
      withBreadcrumbs
      isLoading={statusDetailQuery.isLoading || statusDetailQuery.isFetching}
    >
      {statusData && (
        <Details>
          <DetailsSection>
            <SectionTitle title="Status Details" />
            <SectionBody>
              <SectionGrid colCount={3}>
                <LabelAndValue label="Account" value={statusData.account} />
                <LabelAndValue label="App" value={statusData.app} />
                <LabelAndValue
                  label={strings.publish.lastSuccessfulDate}
                  value={
                    statusData.lastSuccessfulDate
                      ? moment(statusData.lastSuccessfulDate.createdAt).format(
                          DATE_FORMAT,
                        )
                      : '-'
                  }
                />
                <LabelAndValue
                  label={strings.publish.status}
                  value={displayEnum(statusData.status)}
                />
                <LabelAndValue
                  label={strings.env.env}
                  value={displayEnum(statusData.environment)}
                />
                <LabelAndValue
                  label={strings.publish.date}
                  value={moment(statusData.createdAt).format(DATE_FORMAT)}
                />
                <LabelAndValue
                  label={strings.platforms.platforms}
                  value={statusData.platforms.join(', ') || '-'}
                />
                <LabelAndValue
                  label={strings.publish.publishedBy}
                  value={statusData.publishedBy || '-'}
                />
              </SectionGrid>
            </SectionBody>
          </DetailsSection>
          <DetailsSection>
            <SectionTitle title={strings.changelog.changelogs} />
            <SectionBody>
              <ChangelogsTable
                withTitle={false}
                type="provided"
                changelogs={
                  statusDetailQuery.data?.getStatusByStatusId
                    ?.publishedChangesText ?? []
                }
              />
            </SectionBody>
          </DetailsSection>
          <DetailsSection>
            <SectionTitle title={strings.errors.error} />
            <SectionBody>
              {!statusData.logs ||
              statusData.logs.length === 0 ||
              statusData.status !== Status.Failed ? (
                <Text>No logs</Text>
              ) : (
                <VStack alignItems="flex-start">
                  {statusData.logs.map((log, index) => (
                    <Text key={index}>{log.message}</Text>
                  ))}
                </VStack>
              )}
            </SectionBody>
          </DetailsSection>
        </Details>
      )}
    </Page>
  );
}
