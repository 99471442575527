import { createGqlAuthRequest } from '.';
import {
  AllAppsQueryVariables,
  AllAppsQuery,
  AllAppsDocument,
  AccountAppsQueryVariables,
  AccountAppsQuery,
  AccountAppsDocument,
  AppQueryVariables,
  AppDocument,
  AppDetailQueryVariables,
  AppDetailQuery,
  AppDetailDocument,
  UpdateAppMutationVariables,
  UpdateAppMutation,
  UpdateAppDocument,
  CreateAppMutationVariables,
  CreateAppMutation,
  CreateAppDocument,
  DeleteAppMutationVariables,
  DeleteAppMutation,
  DeleteAppDocument,
  PublishAppMutationVariables,
  PublishAppMutation,
  PublishAppDocument,
  RevertAppMutationVariables,
  RevertAppMutation,
  RevertAppDocument,
  AppQuery,
  TriggerConfigWriteMutationVariables,
  TriggerConfigWriteMutation,
  TriggerConfigWriteDocument,
  GenerationStatusQueryVariables,
  GenerationStatusQuery,
  GenerationStatusDocument,
  AppsStatusesQueryVariables,
  AppsStatusesQuery,
  AppsStatusesDocument,
  StatusDetailQueryVariables,
  StatusDetailQuery,
  StatusDetailDocument,
  RemoveDeleteAppRequestDocument,
  RemoveDeleteAppRequestMutation,
  RemoveDeleteAppRequestMutationVariables,
  ChangelogsQueryVariables,
  ChangelogsQuery,
  ChangelogsDocument,
  CancelDeleteAppPlatformRequestDocument,
  CancelDeleteAppPlatformRequestMutationVariables,
  CancelDeleteAppPlatformRequestMutation,
} from '../gql/gqlRequests';

export const allAppsRequest = createGqlAuthRequest<
  AllAppsQueryVariables,
  AllAppsQuery
>(AllAppsDocument);

export const accountAppsRequest = createGqlAuthRequest<
  AccountAppsQueryVariables,
  AccountAppsQuery
>(AccountAppsDocument);

export const appRequest = createGqlAuthRequest<AppQueryVariables, AppQuery>(
  AppDocument,
);

export const appDetailRequest = createGqlAuthRequest<
  AppDetailQueryVariables,
  AppDetailQuery
>(AppDetailDocument);

export const generationStatusRequest = createGqlAuthRequest<
  GenerationStatusQueryVariables,
  GenerationStatusQuery
>(GenerationStatusDocument);

export const appsStatusesRequest = createGqlAuthRequest<
  AppsStatusesQueryVariables,
  AppsStatusesQuery
>(AppsStatusesDocument);

export const statusDetailRequest = createGqlAuthRequest<
  StatusDetailQueryVariables,
  StatusDetailQuery
>(StatusDetailDocument);

export const getChangelogRequest = createGqlAuthRequest<
  ChangelogsQueryVariables,
  ChangelogsQuery
>(ChangelogsDocument);

export const createAppRequest = createGqlAuthRequest<
  CreateAppMutationVariables,
  CreateAppMutation
>(CreateAppDocument);

export const updateAppRequest = createGqlAuthRequest<
  UpdateAppMutationVariables,
  UpdateAppMutation
>(UpdateAppDocument);

export const deleteAppRequest = createGqlAuthRequest<
  DeleteAppMutationVariables,
  DeleteAppMutation
>(DeleteAppDocument);

export const publishAppRequest = createGqlAuthRequest<
  PublishAppMutationVariables,
  PublishAppMutation
>(PublishAppDocument);

export const revertAppRequest = createGqlAuthRequest<
  RevertAppMutationVariables,
  RevertAppMutation
>(RevertAppDocument);

export const triggerConfigWrtiteRequest = createGqlAuthRequest<
  TriggerConfigWriteMutationVariables,
  TriggerConfigWriteMutation
>(TriggerConfigWriteDocument);

export const removeDeleteAppRequest = createGqlAuthRequest<
  RemoveDeleteAppRequestMutationVariables,
  RemoveDeleteAppRequestMutation
>(RemoveDeleteAppRequestDocument);

export const cancelAppPlatformDeletionRequest = createGqlAuthRequest<
  CancelDeleteAppPlatformRequestMutationVariables,
  CancelDeleteAppPlatformRequestMutation
>(CancelDeleteAppPlatformRequestDocument);
