import { LocaleCode } from './locale';

export enum ActionType {
  ADD_SCREEN_FEED,
  PARTIALLY_UPDATE_SCREEN_CONTEXT,
  REMOVE_SCREEN_FEED,
  REORDER_SCREEN_FEEDS,
  RESET,
  SET_ALL,
  SET_BOOLEAN,
  SET_FEATURES,
  SET_INTEGRATION_KEY,
  SET_INTEGRATION_TYPE,
  SET_LOCALIZATION,
  SET_SCREEN_TYPE,
  SET_SETTINGS,
  SET_STRING,
  SET_STRINGS,
  SET_NUMBER,
  SET_ACTION,
  SET_LAYOUT,
  SET_ITEM_LAYOUT,
  UPDATE_SCREEN_FEED,
  UPDATE_INITIAL_STATE,
  SET_CERTIFICATION,
}

export type FileType = 'svg' | 'png' | 'jpeg';
export type FormType = 'create' | 'edit';
export type ViewType = 'detail' | 'form';

export type AcceptedMimeType<T extends string = FileType> = T extends 'svg'
  ? `image/svg+xml`
  : `image/${T}`;

export enum NavEntry {
  ACCOUNTS = 'Accounts',
  APP = 'App',
  FEEDS = 'Feeds',
  SCREENS = 'Screens',
  THEME = 'Theme',
  TAGS = 'Tags',
  PLATFORMS = 'Platforms',
  USERS = 'Users',
}

export type PageTab = {
  name: string;
  destination: string;
};

export type Locale = {
  name: string;
  code: LocaleCode;
};

export enum RequestErrorCode {
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  UNPROCESSABLE_ENTITY = 422,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  UNAUTHENTICATED = 401,
  BAD_REQUEST = 400,
  BAD_USER_INPUT = 400,
  UNDEFINED = 0,
}

export type RequestError = {
  response: {
    errors: {
      message: string;
      path: string[];
      extensions?: {
        errors: {
          property: string;
          constraints?: Record<string, string>[];
        }[];
        code: string;
        response?: {
          statusCode?: number;
        };
        exception: {
          stacktrace: string[];
          $metadata?: {
            httpStatusCode?: number;
          };
        };
      };
    }[];
    // response also has more properties we don't need: data, status, headers, requests
  };
};
